<script setup>
import { computed, ref } from 'vue'
import { useStore } from 'vuex'
import { isValidPhoneNumber, parsePhoneNumber } from 'libphonenumber-js'
import { useAuthentication } from '@/composables/authentication'
import { usePasswords } from '@/composables/passwords'
import PasswordField from '@/components/ui/fields/PasswordField.vue'
import InputOtp from 'primevue/inputotp'

InputOtp.compatConfig = { MODE: 3 }

const { reset, confirmReset } = useAuthentication()
const {
  password,
  confirmPassword,
  passwordsMatch,
  showPasswordMatch,
  passwordStrength,
  updatePasswordStrength,
  setPasswordValid
} = usePasswords()

const store = useStore()

const emit = defineEmits(['cancel'])

const props = defineProps({
  provideUsername: {
    type: String,
    required: false,
    default: ''
  },
  requiredReset: {
    type: Boolean,
    required: false,
    default: false
  },
  startStage: {
    type: String,
    default: 'start'
  }
})

const username = ref(props.provideUsername)
const passwordResetStage = ref(props.startStage)
const requiresCode = ref(false)
const resetCode = ref(null)
const loading = ref(false)

const validClasses = ref('border-black')
const invalidClasses = ref('border-red-400')
const activeButtonClass = ref('bg-black text-level-yellow cursor-pointer')
const disabledButtonClass = ref(
  'bg-cool-gray-300 border-surface-200 text-cool-gray-600 cursor-not-allowed'
)

const canResetPassword = computed(() => {
  if (!password.value) {
    return false
  }
  if (!passwordStrength.value.valid) {
    return false
  }
  if (!passwordsMatch.value) {
    return false
  }
  if (!resetCode.value) {
    return false
  }
  // Password can therefore be reset
  return true
})

const canRequestReset = computed(() => {
  return !(!username.value || !usernameIsValid.value)
})

const usernameIsEmail = computed(() => {
  return /^\S+@\S+\.\S+$/.test(username.value)
})

const usernameIsPhone = computed(() => {
  return isValidPhoneNumber(username.value, 'US')
})

const usernameIsValid = computed(() => {
  if (username.value === '') {
    return true
  }

  return username.value && (usernameIsEmail.value || usernameIsPhone.value)
})

function cancel() {
  emit('cancel')
}

async function requestReset() {
  loading.value = true
  try {
    let result
    if (usernameIsPhone.value) {
      const parsedNum = parsePhoneNumber(username.value, 'US')
      result = await reset(parsedNum.format('E.164'))
    } else {
      result = await reset(username.value)
    }
    passwordResetStage.value = result.isPasswordReset ? 'complete' : 'requested'
    requiresCode.value = result.nextStep.resetPasswordStep === 'CONFIRM_RESET_PASSWORD_WITH_CODE'
  } catch (e) {
    if (e.name === 'LimitExceededException') {
      await store.dispatch('alert', {
        error: true,
        message: e.message
      })
    } else if (e.message === 'User password cannot be reset in the current state.') {
      // User is in a temporary state
      passwordResetStage.value = 'blocked'
      resendLoginLink()
    } else {
      // If the user isn't found, we should still continue. This swallows other errors though
      passwordResetStage.value = 'requested'
    }
  }
  loading.value = false
}

async function confirmPasswordReset() {
  loading.value = true
  try {
    await confirmReset(resetCode.value, username.value, password.value)
    passwordResetStage.value = 'complete'
  } catch (e) {
    await store.dispatch('alert', {
      error: true,
      message: e.message || 'Could not update password.  Please try again.'
    })

    loading.value = false
    throw e
  }

  loading.value = false
}

async function resendLoginLink() {
  await store
    .dispatch('ajax', {
      path: '/auth/resendLoginLink',
      data: {
        username: username.value,
        redirect: '/'
      }
    })
    .then(() => {
      store.dispatch('alert', {
        message: 'Check your email for a new link'
      })
    })
}
</script>

<template>
  <div class="flex flex-col items-center text-center gap-y-8 max-w-[400px] mx-auto">
    <template v-if="passwordResetStage === 'start'">
      <template v-if="requiredReset">
        <p>Before signing in, we require you to confirm your account. Request a code below.</p>
      </template>
      <div v-else class="flex flex-col gap-y-4 text-center">
        <span class="text-2xl">Reset your password</span>
        <p class="text-pitch-black-500">
          Enter your registered email or phone number below and we will send you instructions to
          reset your password
        </p>
      </div>
      <!-- Keep this in a separate form to prevent password managers from thinking it's the log-in form -->
      <form class="flex flex-col gap-y-2 w-full">
        <label for="username" class="text-sm font-medium">Enter your email or phone number</label>
        <input
          name="username"
          type="text"
          v-model="username"
          class="p-2 rounded border-2"
          :class="[usernameIsValid ? validClasses : invalidClasses]"
        />
        <span v-if="!usernameIsValid" class="text-sm text-red-400">
          Please enter a valid email address or phone number
        </span>
      </form>
      <div class="flex flex-col gap-y-4 items-center">
        <button
          @click.stop.prevent="requestReset"
          class="inline-flex py-2 px-4 rounded items-center"
          :class="[canRequestReset ? activeButtonClass : disabledButtonClass]"
          :disabled="!canRequestReset"
        >
          <font-awesome-icon v-show="loading" icon="spinner" class="mr-2" fixed-width spin /> Send
          recovery code
        </button>
        <span class="inline-flex cursor-pointer" @click.stop.prevent="cancel">Back to log in</span>
      </div>
    </template>
    <template v-else-if="passwordResetStage === 'requested'">
      <div
        class="w-fit rounded-full border-2 border-matcha-500 text-matcha-500 px-6 pt-[27px] pb-[28px]"
      >
        <font-awesome-icon icon="paper-plane" fixed-width size="2xl" />
      </div>
      <div class="flex flex-col items-center text-2xl font-medium text-center">
        <p>We have sent the password link to</p>
        <span class="font-bold">{{ username }}</span>
        <p>for instructions to reset your password</p>
      </div>
      <button
        @click.stop.prevent="() => (passwordResetStage = 'confirm')"
        class="py-2 px-4 rounded border border-black"
      >
        Enter code
      </button>
      <button @click.stop.prevent="requestReset" class="py-2 px-4 rounded border border-black">
        <font-awesome-icon v-show="loading" icon="spinner" class="mr-2" fixed-width spin /> Resend
        email
      </button>
    </template>
    <template v-else-if="passwordResetStage === 'confirm'">
      <div
        class="w-fit rounded-full border-2 border-matcha-500 text-matcha-500 px-6 pt-[27px] pb-[28px]"
      >
        <font-awesome-icon icon="laptop-binary" fixed-width size="2xl" />
      </div>
      <p class="text-2xl font-medium">Enter secure code from your email</p>
      <div class="flex flex-col gap-y-8 items-center">
        <InputOtp
          v-model="resetCode"
          integer-only
          :length="6"
          data-1p-ignore
          autocomplete="one-time-code"
        />
        <form>
          <input name="username" type="text" :value="username" class="hidden" aria-hidden="true" />
          <div>
            <label for="password" class="mx-1 !mt-8 !mb-0">Enter new password</label>
            <PasswordField
              context="forgot"
              intent="new"
              v-model="password"
              @submit="confirmPasswordReset"
              @score="updatePasswordStrength"
              @valid="setPasswordValid"
              placeholder="Enter new password"
              class="!h-field rounded !m-1 !text-lg !text-left !font-medium w-full"
            />
          </div>
          <warning v-if="passwordStrength.weak">
            <div v-if="passwordStrength.warning">{{ passwordStrength.warning }}</div>
            <div v-if="passwordStrength.warning && passwordStrength.suggestions">
              <br />
            </div>
            <div v-if="passwordStrength.suggestions">
              <strong>Hints:</strong>
              <div
                v-for="suggestion in passwordStrength.suggestions"
                class="danger"
                :key="suggestion"
              >
                {{ suggestion }}
              </div>
            </div>
          </warning>
          <div>
            <label for="confirm" class="mx-1 !mt-8 !mb-0">Confirm your password</label>
            <PasswordField
              context="forgot"
              intent="confirm"
              v-model="confirmPassword"
              @submit="confirmPasswordReset"
              placeholder="Re-enter your new password"
              class="!h-field rounded !m-1 !text-lg !text-left !font-medium w-full"
            />
          </div>
          <div v-show="showPasswordMatch">
            <danger>Passwords do not match</danger>
          </div>
        </form>
      </div>
      <button
        class="inline-flex py-2 px-4 rounded"
        :class="[canResetPassword ? activeButtonClass : disabledButtonClass]"
        @click="confirmPasswordReset"
        :disabled="!canResetPassword"
      >
        <font-awesome-icon v-show="loading" icon="spinner" class="mr-2" fixed-width spin /> Reset
        password
      </button>
    </template>
    <template v-else-if="passwordResetStage === 'complete'">
      <div
        class="w-fit rounded-full border-2 border-matcha-500 text-matcha-500 px-6 pt-[27px] pb-[28px]"
      >
        <font-awesome-icon icon="shield-check" fixed-width size="2xl" />
      </div>
      <p class="text-2xl font-medium">Password updated!</p>
      <p>You can now log in to your Bolster account with the updated credentials</p>
      <button
        class="inline-flex py-2 px-4 bg-black text-level-yellow rounded"
        @click.stop.prevent="cancel"
      >
        Back to login
      </button>
    </template>
    <template v-else-if="passwordResetStage === 'blocked'">
      <div class="w-fit rounded-full border-2 border-red-500 text-red-500 px-6 pt-[27px] pb-[28px]">
        <font-awesome-icon icon="shield-xmark" fixed-width size="2xl" />
      </div>
      <p class="text-2xl font-medium">No password to reset!</p>
      <p>
        You have either not set a password yet, or only used passwordless methods to log in. We have
        sent you a link to log in directly.
      </p>
      <button
        class="inline-flex py-2 px-4 bg-black text-level-yellow rounded"
        @click.stop.prevent="cancel"
      >
        Back to login
      </button>
    </template>
  </div>
</template>

<style scoped lang="scss"></style>
